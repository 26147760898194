<template>
<transition>
  <div v-if="LiveEvent && LiveVideo" class="touch-pinch-zoom">
    <transition mode="out-in">
      <ThronPlayer
        id="live-player"
        ref="player"
        :is-live-event="true"
        :key="LiveVideo.xcontentId+LiveVideo.sessId"
        :config="videoConfig"
        :subtitle-lang="subtitleLang"
        :show-custom-buttons="!showIntro"
        @play="onPlay"
        @pause="onPause"
        @change-subtitle="subtitleLang = $event"
      />
    </transition>
    <transition>
      <div class="intro full-fixed flex items-center justify-center" v-if="showIntro">
        <LogoAndContent :title="LiveEvent.title">
          <Cta @click="onShowPremiere" data-linktracking="engagement:watch-the-premiere">
            {{LiveEvent.ctaLabel}}
          </Cta>
        </LogoAndContent>
      </div>
    </transition>
  </div>
  <Loader v-else />
</transition>
</template>

<script>
import GetQueryLanguage from '@/utils/query-language'
import GetLiveEvent from '@/graphql/GetLiveEvent.gql'
import GetLiveVideo from '@/graphql/GetLiveVideo.gql'
import ThronPlayer from '@/organisms/ThronPlayer'
import Cta from '@/atoms/Cta.vue'
import LogoAndContent from '@/molecules/LogoAndContent.vue'

export default {
  name: 'LivePage',
  components: { Cta, LogoAndContent, ThronPlayer },
  apollo: {
    LiveEvent: {
      query: GetLiveEvent,
      variables () {
        return {
          status: 'PUBLISHED',
          variants: GetQueryLanguage(this.$language.current)
        }
      },
      result () {
        if (this.LiveEvent && this.LiveVideo && this.$root.preloader.visible) {
          this.$root.preloader.visible = false
        }
        this.$root.pushAnalytics('pageload', {
          pageInfo: {
            language: this.$language.current.substr(0, 2),
            pageName: 'grecale:premiere',
            vehicleName: 'grecale'
          }
        })
      }
    },
    LiveVideo: {
      query: GetLiveVideo,
      variables () {
        return {
          status: 'PUBLISHED',
          variants: GetQueryLanguage(this.subtitleLang)
        }
      },
      result () {
        if (this.LiveEvent && this.LiveVideo && this.$root.preloader.visible) {
          this.$root.preloader.visible = false
        }
      }
    }
  },
  created () {
    if (this.$route.name !== 'Home') {
      this.$router.replace({ path: '/' })
    }
  },
  data () {
    return {
      showIntro: true,
      videoPlaying: false,
      subtitleLang: 'off'
    }
  },
  mounted () {
    window.addEventListener('mousemove', this.onUserInteraction, true)
    window.addEventListener('touchstart', this.onUserInteraction, true)
    window.addEventListener('keydown', this.onUserInteraction, true)
  },
  beforeDestroy () {
    clearTimeout(this.timeout)
    window.removeEventListener('mousemove', this.onUserInteraction, true)
    window.removeEventListener('touchstart', this.onUserInteraction, true)
    window.removeEventListener('keydown', this.onUserInteraction, true)
  },
  watch: {
    showIntro (value) {
      this.checkUiVisibility()
    }
  },
  computed: {
    videoConfig () {
      const suffix = this.$root.Status.useVideoBackup ? 'Backup' : ''

      return {
        clientId: this.LiveVideo[`clientId${suffix}`] || this.LiveVideo.clientId,
        xcontentId: this.LiveVideo[`xcontentId${suffix}`] || this.LiveVideo.xcontentId,
        sessId: this.LiveVideo[`sessId${suffix}`] || this.LiveVideo.sessId,
        autoplay: true,
        noSkin: true,
        muted: true,
        poster: false
      }
    }
  },
  methods: {
    checkUiVisibility () {
      clearTimeout(this.timeout)
      if (this.videoPlaying && !this.showIntro) {
        this.timeout = setTimeout(() => this.$events.emit('toggle-ui', false), 2500)
      } else {
        this.$events.emit('toggle-ui', true)
      }
    },
    onUserInteraction () {
      this.$events.emit('toggle-ui', true)
      this.checkUiVisibility()
    },
    onPlay () {
      this.videoPlaying = true
      this.checkUiVisibility()
    },
    onPause () {
      this.videoPlaying = false
      this.checkUiVisibility()
    },
    onShowPremiere () {
      this.showIntro = false
      if (this.$refs.player) this.$refs.player.toggleAudio(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.intro {
  z-index: 2;

  display: flex;

  align-items: center;
  justify-content: center;

  background: rgba($c-blue, 0.8);
  color: $c-white;
}
</style>
